import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'
import { useTransition, animated } from 'react-spring'

import { Link } from 'gatsby'
import { Button, Icon, Logo } from '@aider/ui'
import slugify from 'slugify'

import Seo from './Seo'
import CookieNotice from './CookieNotice'

import { intercom } from '../utils/intercom'

const Layout = ({ children, location }) => {
	const [cookies, setCookie] = useCookies()
	const [showNotice, setShowNotice] = useState(true)
	const [latestNews, setLatestNews] = useState(null)

	const handleShowNotice = val => {
		setShowNotice(val)
		setCookie('cookies_consent', 'accepted', { path: '/' })
	}

	useEffect(() => {
		setShowNotice(cookies.cookies_consent !== 'accepted')
	}, [cookies.cookies_consent])

	const cookieTransitions = useTransition(showNotice, {
		from: { opacity: 0, transform: 'translateY(100%) scale(0.95)' },
		enter: { opacity: 1, transform: 'translateY(0%) scale(1)' },
		leave: { opacity: 0, transform: 'translateY(100%) scale(0.95)' },
	})

	const darkMode = location.pathname.includes('om-oss')

	useEffect(() => {
		// get data from GitHub api
		fetch(`${process.env.GATSBY_AIDER_API_URL}/api/news`)
			.then(response => response.json()) // parse JSON from request
			.then(resultData => {
				setLatestNews(resultData[0])
			}) // set data for the number of stars
	}, [])

	return (
		<div className="font-sans">
			<Seo />
			{cookieTransitions(
				({ item, key, props }) =>
					item && (
						<animated.div
							className="fixed bottom-0 left-0 w-full md:max-w-lg md:ml-8 md:mb-8 z-50"
							key={key}
							style={props}
						>
							<CookieNotice onAccept={handleShowNotice} />
						</animated.div>
					),
			)}
			<div className="bg-gradient-to-br from-green-500 to-blue-500">
				{latestNews && (
					<Link
						className="px-6 py-2 text-center block transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none"
						to={`/nyheter/${slugify(latestNews.title ?? '', {
							lower: true,
						})}`}
					>
						<p className="text-white font-medium text-sm">
							Nyhet! {latestNews.title}
						</p>
					</Link>
				)}
				<div
					className={`transition-colors duration-600 rounded-t-lg ${
						darkMode ? 'bg-blue-900' : 'bg-white'
					}`}
				>
					<nav className="container mx-auto flex flex-wrap justify-between">
						<div className="px-4 sm:px-6 h-20 sm:h-24 flex items-center">
							<Link className="outline-none" to="/">
								<Logo
									fill={darkMode ? 'white' : 'default'}
									className="w-28 sm:w-32"
								/>
							</Link>
						</div>
						<div className="px-4 sm:px-6 h-20 sm:h-24 flex items-center lg:space-x-5 md:order-3">
							<a
								className={`font-medium hidden lg:flex items-center space-x-1 transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none ${
									darkMode ? 'text-white' : 'text-black'
								}`}
								target="_blank"
								rel="noopener noreferrer"
								href="https://app.aider.nu/login"
							>
								<Icon
									name="lock-open"
									className={`h-4 w-4 fill-current ${
										darkMode ? 'text-white' : 'text-black'
									}`}
								/>
								<span>Logga in</span>
							</a>
							<a
								className="outline-none"
								href="https://app.aider.nu/skapa-konto"
							>
								<Button
									className="intercom-cost-estimate-header"
									title="Kom igång gratis"
									variant="primary"
								/>
							</a>
						</div>
						<div
							className={`w-full sm:hidden h-px bg-gradient-to-r from-transparent to-transparent ${
								darkMode
									? 'via-white opacity-20'
									: 'via-gray-300'
							}`}
						/>
						<div className="h-12 md:h-24 flex items-center w-full overflow-scroll md:overflow-visible md:w-auto md:order-2">
							<div className="space-x-5 lg:space-x-7 px-4 flex items-center sm:px-6 md:px-0 lg:px-6 whitespace-nowrap">
								<button
									className={`font-medium transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none ${
										darkMode ? 'text-white' : 'text-black'
									}`}
									onClick={() => intercom('show')}
									id="intercom-chat"
								>
									Hjälpcenter
								</button>
								<Link
									className={`font-medium transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none ${
										darkMode ? 'text-white' : 'text-black'
									}`}
									activeClassName="opacity-50"
									to="/nyheter"
								>
									Nyheter
								</Link>
								<Link
									className={`font-medium transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none ${
										darkMode ? 'text-white' : 'text-black'
									}`}
									activeClassName="opacity-50"
									to="/om-oss"
								>
										Om oss
								</Link>
								<Link
									className={`font-medium transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none ${
										darkMode ? 'text-white' : 'text-black'
									}`}
									activeClassName="opacity-50"
									to="/pris"
								>
									Pris
								</Link>
								<a
									className={`font-medium  leading-tight inline-flex items-center space-x-2 lg:hidden transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none ${
										darkMode ? 'text-white' : 'text-black'
									}`}
									target="_blank"
									rel="noopener noreferrer"
									href="https://app.aider.nu/login"
								>
									<Icon
										name="lock-open"
										className={`h-4 w-4 fill-current ${
											darkMode
												? 'text-white'
												: 'text-black'
										}`}
									/>
									<span>Logga in</span>
								</a>
							</div>
						</div>
						<div
							className={`w-full sm:hidden h-px  bg-gradient-to-r from-transparent to-transparent ${
								darkMode
									? 'via-white opacity-20'
									: 'via-gray-300'
							}`}
						/>
					</nav>
				</div>
			</div>
			<main
				className={`transition-colors duration-600 ${
					darkMode ? 'bg-blue-900' : 'bg-white'
				}`}
			>
				{children}
			</main>
			<footer className="container px-4 sm:px-6 mx-auto pt-12 flex flex-wrap bg-white">
				<div className="w-full lg:w-3/12 mb-12 lg:mb-0">
					<Link className="outline-none" to="/">
						<Logo className="w-28 sm:w-32" />
					</Link>
				</div>
				<div className="w-full lg:w-9/12 flex flex-wrap mb-16 lg:mb-24">
					<div className="w-6/12 lg:w-3/12 mb-8 lg:mb-0">
						<h4 className="text-blue-700 font-medium mb-2">
							Information
						</h4>
						<Link
							className="mb-2 block font-medium transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none"
							activeClassName="opacity-50"
							to="/om-oss"
						>
							Om oss
						</Link>
						<a
							className="mb-2 block font-medium transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none"
							target="_blank"
							rel="noopener noreferrer"
							href="https://intercom.help/aider/sv"
						>
							Hjälpcenter
						</a>
						<Link
							className="mb-2 block font-medium transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none"
							activeClassName="opacity-50"
							to="/nyheter"
						>
							Nyheter
						</Link>
					</div>
					<div className="w-6/12 lg:w-3/12 mb-8 lg:mb-0">
						<h4 className="text-blue-700 font-medium mb-2">
							Våra kontor
						</h4>
						<div className="mb-2 font-medium">Huddinge</div>
						<div className="mb-2 font-medium">Höör</div>
						<div className="mb-2 font-medium">Halmstad</div>
					</div>
					<div className="w-6/12 lg:w-3/12 mb-8 lg:mb-0">
						<h4 className="text-blue-700 font-medium mb-2">
							Använder du Aider?
						</h4>
						<a
							className="mb-2 hidden lg:block font-medium transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none"
							target="_blank"
							rel="noopener noreferrer"
							href="https://app.aider.nu/login"
						>
							Logga in
						</a>
						<a
							className="mb-2 font-medium transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none"
							target="_blank"
							rel="noopener noreferrer"
							href="https://intercom.help/aider/sv"
						>
							Hjälpcenter
						</a>
					</div>
				</div>
				<div className="flex flex-col sm:flex-row w-full justify-between pt-6 pb-6">
					<div className="space-x-3 mb-4 sm:mb-6">
						<a
							className="text-gray-800 transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none"
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.linkedin.com/company/aidergroup/"
						>
							LinkedIn
						</a>
						<a
							className="text-gray-800 transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none"
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.facebook.com/Aider-107101251199911"
						>
							Facebook
						</a>
					</div>
					<a
						className="block mb-4 sm:mb-6 text-gray-800 transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none"
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.allabolag.se/5591772537/aider-group-ab"
					>
						Org nr: 559177-2537
					</a>
					<a
						className="block mb-4 sm:mb-6 text-gray-800 transition-opacity duration-300 hover:opacity-70 focus:opacity-70 focus:outline-none"
						target="_blank"
						rel="noopener noreferrer"
						href="mailto:info@aider.nu"
					>
						info@aider.nu
					</a>
				</div>
			</footer>
		</div>
	)
}

Layout.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	location: PropTypes.object,
}

export default Layout
